<template>
  <div class="content" v-title data-title="临床研究">
    <div class="h80"></div>
    <!-- <div
      style="
        padding: 0px 20px;
        position: fixed;
        background: #fff;
        z-index: 99;
        width: 100%;
        background: #fff;
      "
    >
      <el-button
        style="font-size: 17px"
        type="text"
        icon="el-icon-arrow-left"
        @click="$router.go(-1)"
        >返回</el-button
      >
    </div> -->
    <!-- <div style="height: 40px"></div> -->
    <el-row class="block-col-2 top">
      <el-col :span="8" :xs="8" class="box">
        <el-dropdown trigger="click" @command="oneClick">
          <span class="el-dropdown-link active">
            {{ one.name }}<i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in oneArr"
              v-show="item.hasChildren"
              :key="index"
              :command="item"
              >{{ item.name }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-col :span="16" :xs="16" class="box">
        <el-dropdown trigger="click" v-if="twoArr.length == 0">
          <span class="el-dropdown-link">
            暂无数据<i class="el-icon-caret-bottom"></i>
          </span>
        </el-dropdown>
        <el-dropdown
          trigger="click"
          @command="twoClick"
          v-if="twoArr.length != 0"
        >
          <span class="el-dropdown-link">
            {{ two.name }}<i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in twoArr"
              v-show="item.hasChildren"
              :key="index"
              :command="item"
              >{{ item.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
    <el-empty
      class="main"
      style="height: 80vh"
      description="暂无数据"
      v-if="threeArr.length == 0"
    ></el-empty>
    <div class="main" v-if="threeArr != 0">
      <div
        class="main_list"
        v-for="(item, index) in threeArr"
        :key="index"
        @click="toDetail(item.id)"
      >
        <div class="main-list-desc">
          <i
            v-show="item.level == '99'"
            class="el-icon-star-on"
            style="color: red; line-height: 30px"
          ></i>
          <div class="maxoneline">
            {{ item.name }}
          </div>
          <i
            class="el-icon-s-flag"
            v-if="item.level != '96'"
            :class="
              item.level == '99' || item.level == '98' ? 'stress' : 'stressless'
            "
          ></i>
        </div>
        <span class="main-item-num">编号：{{ item.projectCode }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { lazyTree } from "@/api/index";
import { wxChatShare } from "../../utils/wechat";
export default {
  data() {
    return {
      one: "",
      oneArr: [],
      two: "",
      twoArr: [],
      threeArr: [],
    };
  },
  created() {
    this.createE().then(() => {
      let params = {
        url: window.location.href,
        title: "临床研究",
        link: window.location.href.replace(window.location.search, ""),
        imgUrl:
          "https://yilian-biz.oss-cn-shanghai.aliyuncs.com//upload/20211112/03eab30b96b9997a72503b690552fb6e.jpeg",
        desc: "为患友寻找更多可能",
        type: "",
        dataUrl:
          "",
      };
      wxChatShare(params);
    });
  },
  methods: {
    async createE() {
      let oneData = await lazyTree(1);
      this.oneArr = oneData.data;
      this.one = this.oneArr[0];
    },
    async oneClick(command) {
      this.one = command;
    },
    twoClick(command) {
      this.two = command;
    },
    toDetail(id) {
      this.$router.push({ name: "recruitDetail", query: { id: id } });
    },
  },
  watch: {
    async one(val) {
      let twoData = await lazyTree(val.id);
      this.twoArr = twoData.data;
      this.two = this.twoArr[0];
      console.log("this.twoArr", this.twoArr, this.twoArr.length);
      console.log("this.two", this.two);
    },
    async two(val) {
      console.log("twoval:", val);
      if (val) {
        let three = await lazyTree(val.id);
        this.threeArr = three.data;
      } else {
        this.threeArr = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  height: 100%;
  background: #f9f9f9;
}
.el-dropdown {
  width: 100%;
}
.box {
  text-align: center;
}
.el-dropdown-link {
  display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.top {
  padding: 10px 0;
  background: #fff;
}
.active {
  color: #3a90d3;
}
.main {
  padding: 0px 20px;
  min-height: 80vh;
}
.main_list {
  font-size: 14px;
  line-height: 30px;
  border-bottom: 1px solid #558bc4;
  padding: 18px 0;
}
.main-list-desc {
  display: flex;
}
.main-item-num {
  color: grey;
  font-size: 12px;
}
.stress {
  margin-left: 10px;
  color: red;
}
.stressless {
  margin-left: 10px;

  color: #38d73b;
}
.maxoneline {
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.el-icon-s-flag {
  line-height: 30px;
}
</style>
